import { generateClient } from 'aws-amplify/api';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import {
  useReactTable,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  PaginationState,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  SortingState,
  SortingFn,
  ColumnFiltersState,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  RowData,
  FilterFn,
} from '@tanstack/react-table';
import RelativeTime from 'react-time-ago';
import { ListTestAccountsQuery } from '../../API';
import { listTestAccounts } from '../../graphql/queries';
import {
  Flex,
  Pagination,
  Placeholder,
  SelectField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
  useTheme,
} from '@aws-amplify/ui-react';
import { Filter } from '../../Components/Filter';
import { useSearchParams } from 'react-router-dom';

declare module '@tanstack/react-table' {
  //allows us to define custom properties for our columns
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: 'select' | 'text';
  }
}

const sortExtras: SortingFn<TestAccountFrontEnd[number]> = (rowA, rowB, _columnId) =>
  (rowA.original.extras.length ?? 0) - (rowB.original.extras.length ?? 0);

const sortRoles: SortingFn<TestAccountFrontEnd[number]> = (rowA, rowB, _columnId) =>
  (rowA.original.roles ?? []).length - (rowB.original.roles ?? []).length;

const filterRoles: FilterFn<TestAccountFrontEnd[number]> = (
  row,
  _columnId,
  filterValue: 'USER' | 'OWNER' | 'PAYER' | 'ALL',
) => {
  if (filterValue === null || filterValue === 'ALL') {
    return true;
  }
  switch (filterValue) {
    case 'OWNER':
      return row.original.roles?.includes('OWNER') ?? false;
    case 'PAYER':
      return row.original.roles?.includes('PAYER') ?? false;
    case 'USER':
      return row.original.roles?.every((it) => it === 'USER') ?? false;
    default:
      return true;
  }
};
const client = generateClient();

export const TestAccountsReactTableView: React.FC<{ highlightMsisdn?: string }> = ({ highlightMsisdn }) => {
  const [querySearchParams, setQuerySearchParams] = useSearchParams();
  //This pagination, sorting and filtering is a quick solution to perserve state
  //in the search url, making the URLS sharable.
  //however, it's not pretty, an ideal soultion would be a better parsing system
  //or even better having a share button that stores this state in the DB and shares only the ID of the query
  //so the url becomes https://www.example.com/accounts?shareId={identifier}
  //for now this is a small step in that direction!
  const paginationQuery = querySearchParams.get('p');
  const sortingQuery = querySearchParams.get('s');
  const filteringQuery = querySearchParams.get('f');
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    filteringQuery ? JSON.parse(filteringQuery) ?? [] : [],
  );
  const [sorting, setSorting] = React.useState<SortingState>(sortingQuery ? JSON.parse(sortingQuery) ?? [] : []);

  const [pagination, setPagination] = React.useState<PaginationState>(
    paginationQuery
      ? JSON.parse(paginationQuery)
      : {
          pageIndex: 1,
          pageSize: 5,
        },
  );

  const columnHelper = createColumnHelper<TestAccountFrontEnd[number]>();
  const [data, setData] = useState<TestAccountFrontEnd>([]);
  const [loading, setLoading] = useState(true);
  const fetchTestaccounts = async () => {
    setLoading(true);
    const result = (await client.graphql({ query: listTestAccounts, variables: { limit: 10000 } })) as {
      data: ListTestAccountsQuery;
    };
    setLoading(false);
    return result;
  };
  const refreshAllAccounts = useCallback(() => {
    (async () => {
      try {
        const result = await fetchTestaccounts();
        if (result.data.listTestAccounts?.items !== undefined) {
          setData(mapData(result.data.listTestAccounts.items));
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, []);

  useEffect(() => {
    refreshAllAccounts();
  }, [refreshAllAccounts]);

  const columns = useCallback(() => {
    return [
      columnHelper.accessor('type', {
        header: 'Type',
        id: 'type',
        sortUndefined: 'first',
        sortingFn: 'auto',
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
        },
      }),
      columnHelper.accessor('userName', {
        header: 'Customer Name',
        id: 'userName',
        sortUndefined: 'first',
        sortingFn: 'textCaseSensitive',
        filterFn: 'includesString',
        meta: {
          filterVariant: 'text',
        },
      }),
      columnHelper.accessor('subscription', {
        header: 'Subscription',
        id: 'subscription',
        sortingFn: 'auto',
        filterFn: 'includesString',
        meta: {
          filterVariant: 'text',
        },
      }),
      columnHelper.accessor('connectId', {
        header: 'Connect ID',
        id: 'connectId',
        sortingFn: 'auto',
        filterFn: 'includesString',
        meta: {
          filterVariant: 'text',
        },
      }),
      columnHelper.accessor('msisdn', {
        header: 'Msisdn',
        id: 'msisdn',
        sortingFn: 'auto',
        filterFn: 'includesString',
        meta: {
          filterVariant: 'text',
        },
      }),
      columnHelper.accessor('roles', {
        header: 'Roles',
        id: 'roles',
        sortingFn: sortRoles,
        cell: (props) => props.getValue()?.sort().join(','),
        filterFn: filterRoles,
        meta: {
          filterVariant: 'select',
        },
      }),
      columnHelper.accessor('family', {
        header: 'Family',
        sortingFn: 'auto',
      }),
      columnHelper.accessor('extras', {
        header: 'Extras',
        sortingFn: sortExtras,
        cell: (props) => props.getValue().sort().join(','),
        filterFn: 'arrIncludes',
        meta: {
          filterVariant: 'select',
        },
      }),
      columnHelper.accessor('updated', {
        header: 'Last Change',
        cell: (props) => <RelativeTime tooltip date={new Date(props.getValue())} />,
        sortingFn: 'datetime',
        enableColumnFilter: false,
      }),
    ];
  }, [columnHelper]);

  const table = useReactTable({
    columns: columns(),
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    state: { pagination, sorting, columnFilters },
  });

  useEffect(() => {
    setQuerySearchParams({
      p: JSON.stringify(pagination, null, 0),
      f: JSON.stringify(columnFilters, null, 0),
      s: JSON.stringify(sorting, null, 0),
    });
  }, [pagination, columnFilters, sorting, setQuerySearchParams]);
  const theme = useTheme();
  if (loading) {
    return (
      <Flex direction="column">
        <Placeholder size="large" />
        <Placeholder size="large" />
        <Placeholder size="small" />
        <Placeholder />
        <Placeholder size="large" />
      </Flex>
    );
  }
  return (
    <Flex direction={'column'} justifyContent={'space-between'}>
      <Table variation="striped" highlightOnHover size={'small'}>
        <TableHead display={{ base: 'none', medium: 'table-header-group' }}>
          {table.getHeaderGroups().map((headerGroup) => (
            <Fragment key={headerGroup.id}>
              <TableRow>
                {headerGroup.headers.map((header) =>
                  header.isPlaceholder ? null : (
                    <TableCell as="th" key={header.id}>
                      {header.column.getCanFilter() ? (
                        <Filter
                          variant={header.column.columnDef.meta?.filterVariant ?? 'text'}
                          column={header.column}
                        />
                      ) : null}
                    </TableCell>
                  ),
                )}
              </TableRow>
              <TableRow>
                {headerGroup.headers.map((header) =>
                  header.isPlaceholder ? null : (
                    <TableCell onClick={header.column.getToggleSortingHandler()} as="th" key={header.id}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted() as string] ?? null}
                    </TableCell>
                  ),
                )}
              </TableRow>
            </Fragment>
          ))}
        </TableHead>
        <TableBody>
          {table.getRowModel().rows.map((row) => (
            <TableRow
              display={{ base: 'block', medium: 'table-row' }}
              key={`${row.getValue('msisdn')}_${row.getValue('connectId')}`}>
              {row.getVisibleCells().map((cell) => (
                <TableCell display={{ base: 'block', medium: 'table-cell' }} key={cell.id}>
                  <Flex justifyContent={'space-between'}>
                    <Text
                      fontWeight={theme.tokens.fontWeights.bold}
                      variation="info"
                      display={{ base: 'block', medium: 'none' }}>{`${cell.column.columnDef.header ?? ''}: `}</Text>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Flex>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Flex justifyContent={'center'}>
        <Pagination
          totalPages={table.getPageCount()}
          currentPage={table.getState().pagination.pageIndex + 1}
          onNext={() => {
            if (table.getCanNextPage()) {
              table.nextPage();
            }
          }}
          onPrevious={() => {
            if (table.getCanPreviousPage()) {
              table.previousPage();
            }
          }}
          onChange={(newIndex) => {
            if (newIndex) table.setPageIndex(newIndex - 1);
          }}
        />

        <SelectField
          value={pagination.pageSize.toString()}
          onChange={(e) =>
            setPagination((old) => {
              const newPagination = {
                pageIndex: old.pageIndex < table.getPageCount() ? old.pageIndex : 1,
                pageSize: e.target.value ? Number(e.target.value) : 5,
              };
              return newPagination;
            })
          }
          label={'Items per page'}
          labelHidden
          options={['5', '10', '15', '25', '50', '100']}
        />
      </Flex>
    </Flex>
  );
};

function nullFilter<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

type TestAccountFrontEnd = ReturnType<typeof mapData>;
function mapData(data: NonNullable<ListTestAccountsQuery['listTestAccounts']>['items']) {
  return data.filter(nullFilter).map((anItem) => {
    const twinSim =
      anItem.handsets?.filter(
        (aHandset) => aHandset?.simCard && aHandset.simCard.type?.toUpperCase().includes('TWIN'),
      ) ?? [];
    const dataSim =
      anItem.handsets?.filter(
        (aHandset) => aHandset?.simCard && aHandset.simCard.type?.toUpperCase().includes('DATA'),
      ) ?? [];
    return {
      type:
        anItem.subscriptionSource === 'ACCOUNT'
          ? 'ACC'
          : anItem.subscriptionSource === 'FIXED'
          ? 'FIXED'
          : anItem.subscriptionSource === 'MOBILE'
          ? 'MOBILE'
          : undefined,
      subscription: anItem.name,
      userName: anItem.userName ?? 'None',
      msisdn: anItem.msisdn,
      connectId: anItem.connectId,
      roles: anItem.roles,
      family: anItem.subscriptionFamily,
      extras: [
        anItem.newSafeProduct,
        anItem.handsets?.some((handset) => handset?.handset?.agreement) ? 'SWAP' : null,
        anItem.subscriptionSource === 'ACCOUNT' && anItem.numberOfMobileSubscriptions !== -1
          ? `Subs (${anItem.numberOfMobileSubscriptions?.toString()} mobile)`
          : null,
        anItem.subscriptionSource === 'ACCOUNT' && anItem.numberOfFixedSubscriptions !== -1
          ? `Subs (${anItem.numberOfFixedSubscriptions?.toString()} fixed)`
          : null,
        twinSim.length > 0 ? 'SIM (' + twinSim.length.toString() + ' twin)' : null,
        dataSim.length > 0 ? 'SIM (' + dataSim.length.toString() + ' data)' : null,
        anItem.hasSafe && anItem.subscriptionSource === 'ACCOUNT' ? 'SAFE' : null,
        anItem.hasIdTheftInsurance && anItem.subscriptionSource === 'ACCOUNT' ? 'ID Theft' : null,
        anItem.hasSvindelforsikring && anItem.subscriptionSource === 'ACCOUNT' ? 'Svindelforsikring' : null,
        anItem.hasSecretNumber ? 'Secret Number' : null,
        ...(anItem.discountTypes?.filter(nullFilter) ?? []),
        anItem.promotedFeature ? anItem.promotedFeature : null,
        anItem.hasCancellableData ? 'Cancellable Datapackage' : null,
        anItem.canOrderSubscription ? 'Can order' : null,
        anItem.hasMPort ? 'MPort' : null,
        anItem.hasEsim ? 'Esim' : null,
        anItem.hasQRCode ? 'QRcode' : null,
        anItem.invoices?.invoices?.some((invoice) => invoice?.status === 'OPEN') ? 'Invoice (open)' : null,
        anItem.invoices?.invoices?.some((invoice) => invoice?.status === 'PARTIALLY_PAID')
          ? 'Invoice (partly paid)'
          : null,
        anItem.invoices?.invoices?.some((invoice) => invoice?.status === 'DEBT_COLLECTION_OPEN')
          ? 'Invoice (dept coll.)'
          : null,
        (anItem.invoices?.creditMemos?.length ?? 0) > 0 ? 'Credit memo' : null,
        (anItem.invoices?.vippsReceipts?.length ?? 0) > 0 ? 'Vipps receipt' : null,
      ].filter(nullFilter),
      updated: anItem.updatedAt,
    };
  });
}
